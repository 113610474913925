export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"robots","content":"noindex, nofollow"},{"name":"charset","content":"utf-8"},{"property":"og:title","content":"Wicked Woods Ticket Sales"},{"property":"og:description","content":"Buy tickets for Wicked Woods Music Festival, August 29 - September 1, 2025"},{"property":"og:type","content":"website"},{"property":"og:url","content":"https://staging.tickets.wickedwoods.ca"},{"property":"og:image","content":"https://staging.tickets.wickedwoods.ca/images/active/meta-og-image.png"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/images/active/favicon.png"},{"rel":"preload","type":"image/gif","href":"/images/active/loader.gif","as":"image"}],"style":[],"script":[],"noscript":[],"title":"Get Wicked","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null